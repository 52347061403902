<template>
    <sdCards title="Filters ">
        <a-form :model="formState" layout="vertical">
            <a-row :gutter="25">
                <a-col :span="hasNumberFilter ? 6 : 6">
                    <a-form-item label="Partner" name="partnerId">
                        <a-select v-model:value="formState.partnerId" placeholder="Please choose partner" show-search
                            style="width: 100%" :options="partnerData" :not-found-content="partnerFetching ? undefined : null
                                " :filter-option="false" @search="searchPartner">
                            <template v-if="partnerFetching" #notFoundContent>
                                <a-spin size="small" />
                            </template>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="hasNumberFilter ? 6 : 6">
                    <a-form-item name="date" label="Date From">
                        <a-date-picker :picker="'start'" v-model:value="formState.dateStart" :style="{ width: '100%' }"
                            format="DD/MM/YYYY" />
                    </a-form-item>
                </a-col>

                <a-col :span="hasNumberFilter ? 6 : 6">
                    <a-form-item name="date" label="Date To">
                        <a-date-picker :picker="'start'" v-model:value="formState.dateEnd" :style="{ width: '100%' }"
                            format="DD/MM/YYYY" />
                    </a-form-item>
                </a-col>
                <a-col :span="hasNumberFilter ? 6 : 6" v-if="hasNumberFilter">
                    <a-form-item name="number" label="Number">
                        <a-input class="custom" v-model:value="formState.number" :style="{ width: '100%' }" />
                    </a-form-item>
                </a-col>
                <a-col v-if="hasPaymentStatusFilter" :span="hasPaymentStatusFilter ? 6 : 6">
                    <a-form-item name="paymentStatus" label="Payment Status">
                        <a-select v-model:value="formState.paymentStatus" placeholder="Payment Status">
                            <a-select-option :value="1">
                                Not paid</a-select-option>
                            <a-select-option :value="2">
                                Partial paid</a-select-option>
                            <a-select-option :value="3">
                                Fully paid</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col v-if="hasPoFilter" :span="hasPoFilter ? 6 : 6">
                    <a-form-item name="customerPoNumber" label="Customer Po Number">
                        <a-input class="custom" v-model:value="formState.customerPoNumber" :style="{ width: '100%' }" />
                    </a-form-item>
                </a-col>
                <a-col :span="6" class="button-container">
                    <a-button class="btn ant-btn-lg client-form-submit" outlined type="primary" @click="onFilter()">
                        <span>Filter</span>
                    </a-button>
                </a-col>
                <a-col :span="6" class="button-container">
                    <a-button @click="clearFilters()" danger>
                        <span>Clear Filters</span>
                    </a-button>
                </a-col>
            </a-row>
        </a-form>
    </sdCards>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref
} from "vue";
import { useStore } from "vuex";
import usePartnerSearch from "../../composable/usePartnerSearch";


const GlobalFilters = defineComponent({
    name: "GlobalFilters",
    props: {
        hasPoFilter: {
            type: Boolean,
            default: false
        },
        hasNumberFilter: {
            type: Boolean,
            default: false
        },
        hasPaymentStatusFilter: {
            type: Boolean,
            default: false
        },
    },
    setup(props, { emit }) {
        const { dispatch } = useStore();
        const dateRange = ref([]);
        const formState = reactive({
            layout: "vertical",
            number: "",
            customerPoNumber: "",
            partnerId: "",
            paymentStatus: "",
            dateStart: null,
            dateEnd: null,
        });

        onMounted(() => {
            dispatch("partnerInfo");
        });

        const clearFilters = () => {
            dateRange.value = [];
            formState.number = "";
            formState.customerPoNumber = "";
            formState.dateStart = null;
            formState.dateEnd = null;
            formState.partnerId = "";
            formState.paymentStatus = "";
            emit('onFilter', formState);
        };

        const onFilter = () => {
            emit('onFilter', formState);
        };
        // #end region

        const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(
            formState.partnerId
        );

        return {
            formState,
            partnerFetching,
            partnerData,
            searchPartner,
            clearFilters,
            onFilter,
            dateRange,
        };
    },
});
export default GlobalFilters;
</script>
<style lang="scss" scoped>
.custom,
.ant-calendar-picker-input.ant-input {
    height: 38px;
}

.button-container {
    align-self: center !important;

    .ant-btn {
        height: 38px;
        width: 100%;
    }
}

.table-actions {
    gap: 10px;
    display: flex;
}

.card-actions {
    gap: 10px;
    display: flex;
}
</style>
